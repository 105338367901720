import React from "react";

export default function Spinner() {
  document.body.style = "background: none";

  return (
    <div className="center-spinner">
      <div className="spinner-grow" role="status">
        <span className="sr-only">Cargando...</span>
      </div>
    </div>
  );
}
