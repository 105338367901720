// import React from "react";

import MainLayout from "../../layouts/MainLayout";
import MenuProvider from "../Utils/MenuContext";
// import LoadingCommons from "../Utils/LoadingCommons";

export default function AuthenticatedApp(props) {
  // console.log("Autenticado");
  return (
    // <LoadingCommons>
    <MenuProvider>
      <MainLayout />
    </MenuProvider>
    // </LoadingCommons>
  );
}
