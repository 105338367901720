import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import CardComponent from "../../views/Templates/CardComponent";
import useLoadingCommons from "./LoadingCommons";

const MenuContext = React.createContext();

export default function MenuProvider(props) {
  const [progress, setProgress] = useState(0);
  const [load_obj, setLoadObj] = useState({});
  const loading_commons = useLoadingCommons();

  const CarreraDetalleView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, carrera_detalle: false }));
    return import("../../views/Carrera/CarreraDetalleView").then((e) => {
      setLoadObj((prev) => ({ ...prev, carrera_detalle: true }));
      return e;
    });
  });
  const CarreraGeneralView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, carrera_general: false }));
    return import("../../views/Carrera/CarreraGeneralView").then((e) => {
      setLoadObj((prev) => ({ ...prev, carrera_general: true }));
      return e;
    });
  });
  const PersonaDetalleView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, persona_detalle: false }));
    return import("../../views/Persona/PersonaDetalleView").then((e) => {
      setLoadObj((prev) => ({ ...prev, persona_detalle: true }));
      return e;
    });
  });
  const PersonaTableView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, docente_table: false }));
    return import("../../views/PersonaTableView").then((e) => {
      setLoadObj((prev) => ({ ...prev, docente_table: true }));
      return e;
    });
  });
  const DocenteHomeView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, docente_home: false }));
    return import("../../views/DocenteHomeView").then((e) => {
      setLoadObj((prev) => ({ ...prev, docente_home: true }));
      return e;
    });
  });
  const AlumnoHomeView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, alumno_home: false }));
    return import("../../views/AlumnoHomeView").then((e) => {
      setLoadObj((prev) => ({ ...prev, alumno_home: true }));
      return e;
    });
  });
  const AlumnoBusquedaExtendida = loadable(() => {
    setLoadObj((prev) => ({ ...prev, alumno_busqueda: false }));
    return import("../../views/AlumnoBusquedaExtendida").then((e) => {
      setLoadObj((prev) => ({ ...prev, alumno_busqueda: true }));
      return e;
    });
  });
  const AlumnoDetalleView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, alumno_detalle: false }));
    return import("../../views/Alumno/AlumnoDetalleView").then((e) => {
      setLoadObj((prev) => ({ ...prev, alumno_detalle: true }));
      return e;
    });
  });
  const HomeView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, home: false }));
    return import("../../views/HomeView").then((e) => {
      setLoadObj((prev) => ({ ...prev, home: true }));
      return e;
    });
  });
  const WorkingView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, working: true }));
    return import("../../views/WorkingView").then((e) => {
      setLoadObj((prev) => ({ ...prev, working: true }));
      return e;
    });
  });
  // const EmisionComprobantesView = loadable(() => {
  //   setLoadObj((prev) => ({ ...prev, emision_comprobantes: false }));
  //   return import("../../views/Contable/EmisionComprobantesView").then((e) => {
  //     setLoadObj((prev) => ({ ...prev, emision_comprobantes: true }));
  //     return e;
  //   });
  // });
  // const RecibosIngresoView = loadable(() => {
  //   setLoadObj((prev) => ({ ...prev, recibos_ingreso: false }));
  //   return import("../../views/Contable/RecibosIngresoView").then((e) => {
  //     setLoadObj((prev) => ({ ...prev, recibos_ingreso: true }));
  //     return e;
  //   });
  // });
  const InstitucionGeneralView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, institucion: false }));
    return import("../../views/Institucional/InstitucionGeneralView").then(
      (e) => {
        setLoadObj((prev) => ({ ...prev, institucion: true }));
        return e;
      },
    );
  });
  const AdministradorArchivosView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, archivos: false }));
    return import("../../views/GADI/AdministradorArchivosView").then((e) => {
      setLoadObj((prev) => ({ ...prev, archivos: true }));
      return e;
    });
  });
  const ListResoluciones = loadable(() => {
    setLoadObj((prev) => ({ ...prev, list_resoluciones: false }));
    return import("../../views/GADI/ListResoluciones").then((e) => {
      setLoadObj((prev) => ({ ...prev, list_resoluciones: true }));
      return e;
    });
  });
  const ListRegisteredFiles = loadable(() => {
    setLoadObj((prev) => ({ ...prev, registered_files: false }));
    return import("../../views/GADI/ListRegisteredFiles").then((e) => {
      setLoadObj((prev) => ({ ...prev, registered_files: true }));
      return e;
    });
  });
  const ListUnRegisteredFiles = loadable(() => {
    setLoadObj((prev) => ({ ...prev, unregistered_files: false }));
    return import("../../views/GADI/ListUnRegisteredFiles").then((e) => {
      setLoadObj((prev) => ({ ...prev, unregistered_files: true }));
      return e;
    });
  });
  const AlumnoAspiranteDatos = loadable(() => {
    setLoadObj((prev) => ({ ...prev, aspirante_datos: false }));
    return import("../../views/Alumno/AlumnoAspiranteDatos").then((e) => {
      setLoadObj((prev) => ({ ...prev, aspirante_datos: true }));
      return e;
    });
  });
  const AlumnoAspirantePreInscripto = loadable(() => {
    setLoadObj((prev) => ({ ...prev, aspirante_preinscripto: false }));
    return import("../../views/Alumno/AlumnoAspirantePreInscripto").then(
      (e) => {
        setLoadObj((prev) => ({ ...prev, aspirante_preinscripto: true }));
        return e;
      },
    );
  });
  const AlumnoRematriculacionAnual = loadable(() => {
    setLoadObj((prev) => ({ ...prev, rematriculacion_anual: false }));
    return import("../../views/Alumno/AlumnoRematriculacionAnual").then((e) => {
      setLoadObj((prev) => ({ ...prev, rematriculacion_anual: true }));
      return e;
    });
  });
  const AlumnoAspiranteInscripto = loadable(() => {
    setLoadObj((prev) => ({ ...prev, aspirante_inscripto: false }));
    return import("../../views/Alumno/AlumnoAspiranteInscripto").then((e) => {
      setLoadObj((prev) => ({ ...prev, aspirante_inscripto: true }));
      return e;
    });
  });
  const AddPersonaView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, add_persona: false }));
    return import("../../views/Persona/AddPersonaView").then((e) => {
      setLoadObj((prev) => ({ ...prev, add_persona: true }));
      return e;
    });
  });
  const AlumnoListadoEgresados = loadable(() => {
    setLoadObj((prev) => ({ ...prev, list_egresados: false }));
    return import("../../views/AlumnoListadoEgresados").then((e) => {
      setLoadObj((prev) => ({ ...prev, list_egresados: true }));
      return e;
    });
  });
  const AlumnoListadoAraucano = loadable(() => {
    setLoadObj((prev) => ({ ...prev, list_araucano: false }));
    return import("../../views/AlumnoListadoAraucano").then((e) => {
      setLoadObj((prev) => ({ ...prev, list_araucano: true }));
      return e;
    });
  });
  const AlumnoListadoNotas = loadable(() => {
    setLoadObj((prev) => ({ ...prev, list_notas: false }));
    return import("../../views/AlumnoListadoNotas").then((e) => {
      setLoadObj((prev) => ({ ...prev, list_notas: true }));
      return e;
    });
  });

  const WebForm = loadable(() => {
    setLoadObj((prev) => ({ ...prev, webform: false }));
    return import("../../views/Alumno/Forms/WebForm").then((e) => {
      setLoadObj((prev) => ({ ...prev, webform: true }));
      return e;
    });
  });
  const CursadoHomeView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, materia_home: false }));
    return import("../../views/CursadoHomeView").then((e) => {
      setLoadObj((prev) => ({ ...prev, materia_home: true }));
      return e;
    });
  });
  const AlumnoListados = loadable(() => {
    setLoadObj((prev) => ({ ...prev, alumno_listados: false }));
    return import("../../views/AlumnoListados").then((e) => {
      setLoadObj((prev) => ({ ...prev, alumno_listados: true }));
      return e;
    });
  });
  const AlumnoInscripcionMaterias = loadable(() => {
    setLoadObj((prev) => ({ ...prev, alumno_inscripcion_materias: false }));
    return import("../../views/Alumno/AlumnoInscripcionMaterias").then((e) => {
      setLoadObj((prev) => ({ ...prev, alumno_inscripcion_materias: true }));
      return e;
    });
  });
  const AlumnoActualizarEstado = loadable(() => {
    setLoadObj((prev) => ({ ...prev, alumno_actualizar_estado: false }));
    return import("../../views/Alumno/AlumnoActualizarEstado").then((e) => {
      setLoadObj((prev) => ({ ...prev, alumno_actualizar_estado: true }));
      return e;
    });
  });
  const DocenteListados = loadable(() => {
    setLoadObj((prev) => ({ ...prev, docente_listados: false }));
    return import("../../views/DocenteListados").then((e) => {
      setLoadObj((prev) => ({ ...prev, docente_listados: true }));
      return e;
    });
  });
  const ExamenesHomeView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, mesa_examen_home: false }));
    return import("../../views/ExamenesHomeView").then((e) => {
      setLoadObj((prev) => ({ ...prev, mesa_examen_home: true }));
      return e;
    });
  });

  const DirectivoListados = loadable(() => {
    setLoadObj((prev) => ({ ...prev, list_cargos_gestion: false }));
    return import("../../views/DirectivoListados").then((e) => {
      setLoadObj((prev) => ({ ...prev, list_cargos_gestion: true }));
      return e;
    });
  });

  const TutorialesView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, tutoriales_view: false }));
    return import("../../views/GADI/TutorialesView").then((e) => {
      setLoadObj((prev) => ({ ...prev, tutoriales_view: true }));
      return e;
    });
  });

  const TicketMainView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, ticket_main_view: false }));
    return import("../../views/Tickets/TicketMainView").then((e) => {
      setLoadObj((prev) => ({ ...prev, ticket_main_view: true }));
      return e;
    });
  });

  const ReportesView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, reportes_view: false }));
    return import("../../views/ReportesView").then((e) => {
      setLoadObj((prev) => ({ ...prev, reportes_view: true }));
      return e;
    });
  });

  const InscripcionesView = loadable(() => {
    setLoadObj((prev) => ({ ...prev, inscripciones_view: false }));
    return import("../../views/InscripcionesHomeView").then((e) => {
      setLoadObj((prev) => ({ ...prev, inscripciones_view: true }));
      return e;
    });
  });

  const [view, setView] = useState(<HomeView />);
  const [caller, setCaller] = useState("home");
  const [history, setHistory] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let temp =
      50 +
      (Object.values(load_obj).filter((v) => v).length * 50) /
        Object.values(load_obj).length;

    setProgress(Math.round(temp / 10) * 10);

    if (temp > 95)
      setTimeout(() => {
        setLoading(false);
      }, 1000);
  }, [load_obj]);

  function initLoading() {
    CarreraDetalleView.preload();
    CarreraGeneralView.preload();
    PersonaDetalleView.preload();
    PersonaTableView.preload();
    DocenteHomeView.preload();
    AlumnoHomeView.preload();
    AlumnoBusquedaExtendida.preload();
    AlumnoDetalleView.preload();
    AlumnoActualizarEstado.preload();
    HomeView.preload();
    WorkingView.preload();
    // EmisionComprobantesView.preload();
    // RecibosIngresoView.preload();
    InstitucionGeneralView.preload();
    AdministradorArchivosView.preload();
    ListRegisteredFiles.preload();
    ListResoluciones.preload();
    ListUnRegisteredFiles.preload();
    AlumnoAspiranteDatos.preload();
    AlumnoAspirantePreInscripto.preload();
    AlumnoRematriculacionAnual.preload();
    AlumnoAspiranteInscripto.preload();
    AddPersonaView.preload();
    AlumnoListadoEgresados.preload();
    AlumnoListadoAraucano.preload();
    WebForm.preload();
    CursadoHomeView.preload();
    AlumnoListados.preload();
    AlumnoListadoNotas.preload();
    AlumnoInscripcionMaterias.preload();
    DocenteListados.preload();
    ExamenesHomeView.preload();
    DirectivoListados.preload();
    TutorialesView.preload();
    TicketMainView.preload();
    ReportesView.preload();
    InscripcionesView.preload();
  }

  function initChildren() {
    setHistory({
      id: "home",
      view: "home",
      arg: "",
      breadcrumb: "inicio",
      parent: "root",
      child: null,
    });
  }

  useEffect(() => {
    initChildren();
  }, []);

  useEffect(() => {
    setProgress(loading_commons.progress);
    if (loading_commons.progress === 50) {
      initLoading();
    }
  }, [loading_commons.progress]);

  function setChild(h, obj) {
    if (h) {
      if (h.id === obj.parent) {
        h.child = obj;
      } else {
        h.child = setChild(h.child, obj);
      }
    }

    return h;
  }

  function handleHistory(obj) {
    let h = JSON.parse(JSON.stringify(history));

    if (obj.view === "home") initChildren();
    else {
      setChild(h, obj);
      setHistory(h);
    }
  }

  function linkViewButton(view, arg, txt_breadcrumb) {
    let x = arg ? view + "_id" : view;

    handleHistory({
      id: x,
      view: view,
      arg: arg,
      breadcrumb: txt_breadcrumb,
      parent: caller,
      child: null,
    });
    handleView(view, arg);
  }

  function linkViewNav(view, arg, txt_breadcrumb) {
    let x = arg ? view + "_id" : view;

    handleHistory({
      id: x,
      view: view,
      arg: arg,
      breadcrumb: txt_breadcrumb,
      parent: "home",
      child: null,
    });
    handleView(view, arg);
  }

  function handleView(view, arg) {
    let x = arg ? view + "_id" : view;

    if (caller !== x) {
      setCaller(x);
    } else {
      return;
    }

    switch (view) {
      case "home":
        setView(<HomeView />);
        break;
      case "institucion":
        setView(<InstitucionGeneralView />);
        break;
      case "list-cargos-gestion":
        setView(<DirectivoListados arg={arg} />);
        break;
      case "carreras":
        if (arg) setView(<CarreraDetalleView arg={arg} />);
        else setView(<CarreraGeneralView />);
        break;
      case "docentes":
        setView(<DocenteHomeView />);
        break;
      case "personas":
        if (arg) setView(<PersonaDetalleView arg={arg} />);
        else setView(<WorkingView />);
        break;
      case "new-persona":
        setView(<AddPersonaView arg={arg} />);
        break;
      case "personas-table":
        setView(<PersonaTableView arg={arg} />);
        break;
      case "list-cargos-docencia":
        setView(<DocenteListados arg={arg} />);
        break;
      case "alumnos":
        if (arg) setView(<AlumnoDetalleView arg={arg} />);
        else setView(<AlumnoHomeView />);
        break;
      case "alumnos-table":
        setView(<AlumnoBusquedaExtendida arg={arg} />);
        break;
      case "alumnos-listado":
        setView(<AlumnoListados />);
        break;
      case "alumnos-notas":
        setView(<AlumnoListadoNotas />);
        break;
      case "alumnos-inscripcion-materias":
        setView(<AlumnoInscripcionMaterias />);
        break;
      case "alumnos-actualizar-estado":
        setView(<AlumnoActualizarEstado />);
        break;
      case "materias":
        setView(<CursadoHomeView arg={arg} />);
        break;
      case "cargar-comprobantes":
        setView(<WorkingView />);
        break;
      // case "emitir-comprobantes":
      //   setView(<EmisionComprobantesView arg={arg} />);
      //   break;
      // case "recibos-ingreso":
      //   setView(<RecibosIngresoView arg={arg} />);
      //   break;
      case "archivos":
        setView(<AdministradorArchivosView arg={arg} />);
        break;
      case "directorio-reg":
        setView(<ListRegisteredFiles arg={arg} />);
        break;
      case "directorio-res":
        setView(<ListResoluciones arg={arg} />);
        break;
      case "directorio-ureg":
        setView(<ListUnRegisteredFiles arg={arg} />);
        break;
      case "aspirante-datos":
        setView(<AlumnoAspiranteDatos arg={arg} />);
        break;
      case "aspirantes-preinscriptos":
        setView(<AlumnoAspirantePreInscripto />);
        break;
      case "aspirantes-inscriptos":
        setView(<AlumnoAspiranteInscripto />);
        break;
      case "rematriculacion-anual":
        setView(<AlumnoRematriculacionAnual />);
        break;
      case "listado-egresados":
        setView(<AlumnoListadoEgresados />);
        break;
      case "listado-araucano":
        setView(<AlumnoListadoAraucano />);
        break;
      case "examenes":
        setView(<ExamenesHomeView />);
        break;
      case "tutoriales":
        setView(<TutorialesView />);
        break;
      case "tickets":
        setView(<TicketMainView />);
        break;
      case "reportes":
        setView(<ReportesView />);
        break;
      // case "alumno-documentacion":
      //   setView(<AlumnoDocumentacion arg={arg} />);
      //   break;
      case "inscripciones":
        setView(<InscripcionesView />);
        break;
      case "webform":
        setView(<WebForm />);
        break;
      default:
        setView(<WorkingView />);
        break;
    }
  }

  if (loading_commons.errores.length > 0) return loading_commons.errores;
  else {
    if (loading) {
      return (
        <div className="content pd-0">
          <div className="container">
            <CardComponent style="mg-t-200 shadow">
              <div className="progress ht-20">
                <div
                  style={{ width: progress + "%" }}
                  className={"progress-bar"}
                  role="progressbar"
                >
                  Cargando {progress}%
                </div>
              </div>
            </CardComponent>
          </div>
        </div>
      );
    } else {
      return (
        <MenuContext.Provider
          value={{
            linkButton: linkViewButton,
            linkNav: linkViewNav,
            setView: handleView,
            currentView: view,
            history: history,
          }}
        >
          {props.children}
        </MenuContext.Provider>
      );
    }
  }
}

export { MenuContext };
