import React, { useContext, useState, useEffect } from "react";
import { config } from "../config.js";
import { AuthContext } from "../components/Authentication/AuthComponent";
import { useForm } from "react-hook-form";
import { useConnection } from "../components/Utils/MyHooks";
import * as utils from "../components/Utils/Utils";

export default function LoginView() {
  const context = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [reset_form, setResetForm] = useState(false);
  // const [success, setSuccess] = useState(false);

  const login = useConnection();
  const reset = useConnection();

  const { register, control, handleSubmit, errors, trigger, getValues } =
    useForm();

  useEffect(() => {
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/");
    });
  }, []);

  const test = window.location.hostname === "siigaa-qa.latechneeducativa.com.ar"

  useEffect(() => {
    if (login.status === "done") {
      if (!login.error) {
        if (Object.keys(login.data).length > 0) {
          context.setToken(login.data);
          let values = getValues();
          // console.log(values);
          if (values.user === values.password) {
            setResetForm(true);
          } else {
            context.doLogin();
          }
        }
      } else {
        console.log(login.error);
        if (login.error.code === 404) {
          setError("Usuario y/o Contraseña Incorrectos");
        } else {
          setError(login.error.msg);
        }
      }
    }
  }, [login.status]);

  useEffect(() => {
    if (reset.status === "done") {
      if (reset.error) {
        let e = [];
        if (reset.error.obj["new_password1"]) {
          e = e.concat(reset.error.obj["new_password1"]);
        }
        if (reset.error.obj["new_password2"]) {
          e = e.concat(reset.error.obj["new_password2"]);
        }

        setError(e);
      } else {
        setError(null);
        context.doLogin();
        // setSuccess(true);
      }
    }
  }, [reset.status]);

  const doPost = handleSubmit((x) => {
    setError(null);
    let form = new FormData();
    form.append("username", x.user);
    form.append("password", x.password);
    login.post(config.api_login, form);
  });

  const doChange = handleSubmit((x) => {
    setError(null);
    let form = new FormData();
    if (x.new_password1 !== x.new_password2) {
      setError(["Las contraseñas no coinciden"]);
      return;
    }

    form.append("new_password1", x.new_password1);
    form.append("new_password2", x.new_password2);
    reset.post(config.api_change_pass, form);
  });

  document.body.style.backgroundImage =
    "linear-gradient(180deg, #009b473d 33%, #009b4705 66%, rgb(255 255 255) 100%)";
  // "linear-gradient(163deg, rgb(111 251 176 / 22%) 33%, rgb(204 12 0 / 18%) 66%, rgb(255 220 220) 100%)";

  return (
    <div className="content content-fixed content-auth pd-t-100">
      <div className="container">
        <div className="login container-fluid sign-wrapper align-items-center pd-t-30 ht-550">
          {test && (
            <h3 className="tx-color-01">Entorno de test</h3>
          )}
          <div className="mg-b-30">
            <img
              src={require("../assets/img/iunir_logo_circle.png")}
              className=""
              alt=""
            />
          </div>
          {reset_form && (
            <div
              className="wd-100p"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  doChange();
                }
              }}
            >
              <h3 className="tx-color-01">Cambiar Contraseña</h3>
              {/* <div className="mg-b-20 tx-11 tx-danger">
                Debe contener:
                <ul className="tx-10">
                  <li>Al menos 8 caracteres</li>
                  <li>Letras y numeros</li>
                  <li>No debe ser similar al nombre de usuario</li>
                </ul>
              </div> */}
              <div className="form form-row mg-t-20">
                <div className="form-group col-md-12 col-lg-12">
                  <label className="tx-sans tx-medium tx-uppercase tx-color-03 tx-10">
                    Nueva Contraseña
                  </label>
                  <input
                    style={{ textTransform: "none" }}
                    id="new_password1"
                    name="new_password1"
                    type="password"
                    className={
                      errors["new_password1"]
                        ? "form-control parsley-error"
                        : "form-control"
                    }
                    placeholder="Al menos 8 caracteres, letras y numeros"
                    ref={register({ required: true })}
                    autoComplete="off"
                    autoFocus
                  />
                </div>
              </div>
              <div className="form form-row mg-t-20">
                <div className="form-group col-md-12 col-lg-12">
                  <label className="tx-sans tx-medium tx-uppercase tx-color-03 tx-10">
                    Confirmar Contraseña
                  </label>
                  {/* <a href="" className="tx-13">
                      Recuperar Contraseña
                    </a> */}
                  <input
                    style={{ textTransform: "none" }}
                    id="new_password2"
                    name="new_password2"
                    type="password"
                    className={
                      errors["new_password2"]
                        ? "form-control parsley-error"
                        : "form-control"
                    }
                    placeholder="Al menos 8 caracteres, letras y numeros"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              <div className="form form-row mg-t-20">
                <div className="form-group col-md-12 col-lg-12">
                  <button
                    className="btn btn-outline-light btn-block"
                    onClick={() => doChange()}
                  >
                    {reset.status === "loading" && "Cambiando..."}
                    {reset.status !== "loading" && "Cambiar"}
                  </button>
                  <div className="mg-t-5">
                    <span className="tx-danger tx-11">
                      {error &&
                        error.map((e) => {
                          return <p className="mg-b-5">{e}</p>;
                        })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!reset_form && (
            <div
              className="wd-100p"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  doPost();
                }
              }}
            >
              <h3 className="tx-color-01 mg-b-20">Inicio de Sesión</h3>
              <div className="form form-row">
                <div className="form-group col-md-12 col-lg-12">
                  <label className="tx-sans tx-medium tx-uppercase tx-color-03 tx-10">
                    Usuario
                  </label>
                  <input
                    style={{ textTransform: "none" }}
                    id="user"
                    name="user"
                    type="text"
                    className={
                      errors["user"]
                        ? "form-control parsley-error"
                        : "form-control"
                    }
                    placeholder="Ingrese su usuario"
                    ref={register({ required: true })}
                    autoComplete="off"
                    autoFocus
                  />
                </div>
              </div>
              <div className="form form-row mg-t-20">
                <div className="form-group col-md-12 col-lg-12">
                  <label className="tx-sans tx-medium tx-uppercase tx-color-03 tx-10">
                    Contraseña
                  </label>
                  {/* <a href="" className="tx-13">
                      Recuperar Contraseña
                    </a> */}
                  <input
                    style={{ textTransform: "none" }}
                    id="password"
                    name="password"
                    type="password"
                    className={
                      errors["password"]
                        ? "form-control parsley-error"
                        : "form-control"
                    }
                    placeholder="Ingrese su contraseña"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
              <div className="form form-row mg-t-20">
                <div className="form-group col-md-12 col-lg-12">
                  <button
                    className="btn btn-outline-light btn-block"
                    onClick={() => doPost()}
                  >
                    {login.status === "loading" && "Comprobando..."}
                    {login.status !== "loading" && "Ingresar"}
                  </button>
                  <div className="mg-t-5">
                    <div
                      className={
                        !error
                          ? "hidden-login-error"
                          : "parsley-errors-list filled parsley-required"
                      }
                    >
                      {error}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="container-fluid sign-wrapper pd-t-10">
          <span className="tx-right tx-11">
            {utils.getVersion().tag + "." + utils.getVersion().commit}
          </span>
        </div>
      </div>
    </div>
  );
}
