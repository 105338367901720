import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Authentication/AuthComponent";
import { MenuContext } from "../Utils/MenuContext";
import { useConnection } from "../../components/Utils/MyHooks";
import { config } from "../../config";
// import loadMainJS from "../../assets/js/dashforge.js";
import loadAsideJS from "../../assets/js/dashforge.aside.js";
import Bootstrap from "bootstrap";

export default function NavBar() {
  const auth = useContext(AuthContext);
  const menu = useContext(MenuContext);
  const session = auth.getSessionData();
  const conn = useConnection();

  const [notifications, setNotifications] = useState([]);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // loadMainJS();
    loadAsideJS();
  }, []);

  useEffect(() => {
    if (conn.status === "done") {
      if (!conn.error) {
        auth.doLogout();
      } else {
        menu.setView("error", "");
      }
    }
  }, [conn.status]);

  return (
    <div id="navbarMenu" className="navbar navbar-header navbar-header-fixed">
      <div className="navbar-right">
        <div className="dropdown dropdown-message">
          <a
            href=""
            className={
              messages.length > 0
                ? "dropdown-link new-indicator"
                : "dropdown-link"
            }
            data-toggle="dropdown"
          >
            <i className="far fa-comment-alt tx-20"></i>
            {/* <i data-feather="message-square"></i> */}
            {messages.length > 0 && <span>{messages.length}</span>}
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <div className="dropdown-header">Mensajes</div>
            <a href="" className="dropdown-item">
              <div className="media">
                <div className="media-body mg-l-15">
                  {messages.length === 0 && <p>No hay nuevos mensajes</p>}
                  {messages.map((e) => {
                    <>
                      <strong>{e.title}</strong>
                      <p>{e.text}</p>
                      <span>{e.last_upload}</span>
                    </>;
                  })}
                </div>
              </div>
            </a>
            {messages.length > 0 && (
              <div className="dropdown-footer">
                <a href="">Todos los mensajes</a>
              </div>
            )}
          </div>
        </div>
        <div className="dropdown dropdown-notification">
          <a
            href=""
            className={
              notifications.length > 0
                ? "dropdown-link new-indicator"
                : "dropdown-link"
            }
            data-toggle="dropdown"
          >
            <i className="far fa-bell tx-20"></i>
            {/* <i data-feather="bell"></i> */}
            {notifications.length > 0 && <span>{notifications.length}</span>}
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <div className="dropdown-header">Notificaciones</div>
            <a href="" className="dropdown-item">
              <div className="media">
                <div className="media-body mg-l-15">
                  {notifications.length === 0 && (
                    <p>No hay nuevas notificaciones</p>
                  )}
                  {notifications.map((e) => {
                    <>
                      {e.text}
                      <span>{e.last_upload}</span>
                    </>;
                  })}
                </div>
              </div>
            </a>
            {notifications.length > 0 && (
              <div className="dropdown-footer">
                <a href="">Todas las notificaciones</a>
              </div>
            )}
          </div>
        </div>
        <div className="dropdown dropdown-profile">
          <a
            href=""
            className="dropdown-link"
            data-toggle="dropdown"
            data-display="static"
          >
            <div className="avatar avatar-sm">
              <img
                src={require("../../assets/img/iunir_logo_circle.png")}
                alt=""
                className="rounded-circle"
              />
            </div>
          </a>
          <div className="dropdown-menu dropdown-menu-right tx-13">
            <div className="avatar avatar-lg mg-b-15">
              <img
                src={require("../../assets/img/iunir_logo_circle.png")}
                alt=""
                className="rounded-circle"
              />
            </div>
            <h6 className="tx-semibold mg-b-5">
              {session && session.first_name + " " + session.last_name}
            </h6>
            <p className="mg-b-25 tx-12 tx-color-03">
              {session && session.email}
            </p>
            {/* <a
              href="#"
              className="dropdown-item"
              onClick={() => console.log("perfil")}
            >
              <i data-feather="user"></i> Perfil
            </a>
            <div className="dropdown-divider"></div>
            <a
              href="#"
              className="dropdown-item"
              onClick={() => console.log("actividad")}
            >
              <i data-feather="activity"></i> Actividad
            </a> */}
            {/* <a
              href="#"
              className="dropdown-item"
              onClick={() => console.log("configuracion")}
            >
              <i data-feather="settings"></i>Configuración
            </a> */}
            <a
              href="#"
              className="dropdown-item"
              onClick={() => conn.post(config.api_logout, {})}
            >
              <i className="fas fa-sign-out-alt tx-20 mg-r-10 tx-color-03"></i>{" "}
              Salir
              {/* <i data-feather="log-out"></i>Salir */}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
