import React, { useState, useEffect } from "react";
import { config } from "../../config";
import * as ver from "../../assets/version.json";
// export const MyContext = React.createContext();

// When we use async/await, we rarely need .then, because await handles the waiting for us.
// And we can use a regular try..catch instead of .catch. That’s usually (not always) more convenient.

// But at the top level of the code, when we’re outside of any async function,
// we’re syntactically unable to use await, so it’s a normal practice to add
// .then/catch to handle the final result or falling-through errors.

const default_txt = "Sin Información";

const meses_txt = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Setiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const tipos_responsables = [
  { id: 1, nombre: "IVA Responsable Inscripto" },
  { id: 2, nombre: "IVA Responsable no Inscripto" },
  { id: 3, nombre: "IVA no Responsable" },
  { id: 4, nombre: "IVA Sujeto Exento" },
  { id: 5, nombre: "Consumidor Final" },
  { id: 6, nombre: "Responsable Monotributo" },
  { id: 7, nombre: "Sujeto no Categorizado" },
  { id: 8, nombre: "Proveedor del Exterior" },
  { id: 9, nombre: "Cliente del Exterior" },
  { id: 10, nombre: "IVA Liberado – Ley Nº 19.640" },
  {
    id: 11,
    nombre: "IVA Responsable Inscripto – Agente de Percepción",
  },
  { id: 12, nombre: "Pequeño Contribuyente Eventual" },
  { id: 13, nombre: "Monotributista Social" },
  { id: 14, nombre: "Pequeño Contribuyente Eventual Social" },
];

const getVersion = () => {
  return ver.default;
};

const normalize_str = (x) => {
  if (x) {
    if (typeof x !== "string") return x;
    else return x.normalize("NFKD").replace(/[\u0300-\u036F]/g, "");
  } else {
    return x;
  }
};

const getCommons = (id, tabla, field = "") => {
  let data = JSON.parse(sessionStorage.getItem(tabla));
  let obj = null;
  if (data) {
    data.forEach((element) => {
      if (element.id === id)
        if (field) obj = element[field];
        else obj = element;
    });
  }
  return obj;
};

const getCommonsAll = (tabla, filter = null) => {
  let data = JSON.parse(sessionStorage.getItem(tabla));

  if (data && data.length > 0)
    if (filter)
      return data.filter(function (element, key) {
        return filter(element);
      });
    else return data;
  else return [];
};

const getOptions = (tabla) => {
  let data = JSON.parse(sessionStorage.getItem(tabla));

  if (data) {
    if (data["actions"] && data["actions"]["POST"])
      return data["actions"]["POST"];
    else return [];
  } else {
    return [];
  }

  // if (data) {
  //   if (key) return data[key];
  //   else return data;
  // } else return [];
};

const getChoices = (tabla, key) => {
  let options = JSON.parse(sessionStorage.getItem(tabla));
  let tmp = [];

  if (options && key) {
    tmp = options["choices"][key];
  }

  return tmp;
};

const getChoiceValue = (tabla, key, value, display_name = false) => {
  let choices = getChoices(tabla, key);
  let tmp = null;

  tmp = choices.find((x) => {
    if (display_name) return x["display_name"] == value;
    else return x["value"] == value;
  });

  if (tmp && display_name) return tmp["value"];
  else if (tmp && !display_name) return tmp["display_name"];
  else return default_txt;
};

const getFormaIngreso = (data) => {
  switch (data) {
    case 1:
      return "Normal (Por Primera Vez)";
    case 2:
      return "Equivalencia";
    default:
      return "Otro";
  }
};

const formatEstadoAlumnoClass = (act) => {
  switch (act) {
    case 0:
      return "badge badge-warning";
    case 1:
      return "badge badge-info";
    case 3:
      return "badge badge-success";
    case 4:
      return "badge badge-danger";
    case 5:
      return "badge bg-gray-200 tx-black";
    case 6:
      return "badge bg-gray-500 tx-white";
    case 7:
      return "badge bg-gray-900 tx-white";
    case 8:
      return "badge badge-primary";
    default:
      return "badge badge-light";
  }
};

const formatLugarNacimientoAlumno = (data) => {
  let lugar_nac = [];

  let pais = getCommons(data["nacimiento_pais"], "pais", "nombre");

  if (data["nacimiento_pais"] && data["nacimiento_pais"] === 54) {
    lugar_nac.push(getCommons(data["nacimiento_prov"], "provincia", "nombre"));
    if (data["nacimiento_loc"] && data["nacimiento_loc"] <= 99000) {
      lugar_nac.push(getCommons(data["nacimiento_loc"], "localidad", "nombre"));
    }
  } else {
    lugar_nac.push(data["localidad_extranjera"]);
  }

  if (pais && pais !== "Indeterminada") {
    lugar_nac.push(pais);
  }

  if (lugar_nac.length == 0) {
    return default_txt;
  }

  return lugar_nac.filter((x) => x !== null).join(", ");
};

function formatFechaNacimiento(data, age = null) {
  let date = formatFecha(data);

  if (date !== "01/01/1000") {
    if (age) return date + " - " + age + " años";
    else return date;
  } else return default_txt;
}

const estado_docente = [
  { id: "A", nombre: "Activo" },
  { id: "J", nombre: "Jubilado" },
  { id: "R", nombre: "Renuncia" },
  { id: "F", nombre: "Fallecido" },
  { id: "L", nombre: "Licencia" },
  { id: "D", nombre: "Despido" },
  { id: "C", nombre: "Cese" },
];

const formatEstadoDocenteClass = (act) => {
  switch (act) {
    case "A":
      return "badge badge-success";
    case "J":
      return "badge badge-primary";
    case "R":
      return "badge badge-danger";
    case "F":
      return "badge badge-dark";
    case "L":
      return "badge badge-info";
    case "D":
      return "badge badge-secondary";
    default:
      return "badge badge-light";
  }
};

const notas = [
  {
    id: 10,
    nombre: 10,
  },
  {
    id: 9,
    nombre: 9,
  },
  {
    id: 8,
    nombre: 8,
  },
  {
    id: 7,
    nombre: 7,
  },
  {
    id: 6,
    nombre: 6,
  },
  {
    id: 5,
    nombre: 5,
  },
  {
    id: 4,
    nombre: 4,
  },
  {
    id: 3,
    nombre: 3,
  },
  {
    id: 2,
    nombre: 2,
  },
  {
    id: 1,
    nombre: 1,
  },
];

const getDuracion = (tipo) => {
  switch (tipo) {
    case "A":
      return "Anual";
    case "M":
      return "Mensual";
    case "B":
      return "Bimensual";
    case "T":
      return "Trimestral";
    case "C":
      return "Cuatrimestral";
    case "S":
      return "Semestral";
    default:
      return "Otra";
  }
};

const getObligatoriedad = (tipo) => {
  switch (tipo) {
    case 2:
      return "Optativa";
    case 1:
      return "Electiva";
    default:
      return "Obligatorio";
  }
};

const getTipoMateria = (tipo) => {
  switch (tipo) {
    case "C":
      return "Curso";
    default:
      return "Materia";
  }
};

const getFileIcon = (file) => {
  let ext = file.split(".").pop();
  let sub = "";
  let icon = "";

  if (ext.includes("doc")) {
    ext = "word";
  }

  if (ext.includes("xls")) {
    ext = "excel";
  }

  if (ext.includes("ppt")) {
    ext = "powerpoint";
  }

  if (["avi", "mp4", "mpg", "mpeg", "mov"].includes(ext)) {
    ext = "video";
  }

  switch (ext) {
    case "pdf":
      sub = "Documento PDF";
      icon = <i className="far fa-file-pdf tx-danger"></i>;
      break;
    case "word":
      sub = "Documento de Word";
      icon = <i className="far fa-file-word tx-primary"></i>;
      break;
    case "excel":
      sub = "Planilla de Excel";
      icon = <i className="far fa-file-excel tx-success"></i>;
      break;
    case "powerpoint":
      sub = "Presentación PowerPoint";
      icon = <i className="far fa-file-powerpoint tx-orange"></i>;
      break;
    case "video":
      sub = "Archivo Multimedia";
      icon = <i className="far fa-file-video tx-pink"></i>;
      break;
    default:
      sub = "Archivo Desconocido";
      icon = <i className="far fa-file-alt tx-gray-600"></i>;
      break;
  }

  return { icon: icon, subtitle: sub };
};

const formatFecha = (data) => {
  let date;
  if (data) {
    date = new Date(data + "T00:00:00");
  } else {
    date = new Date();
  }

  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  return dd + "/" + mm + "/" + yyyy;

  // return new Date(data + " 00:00:00").toLocaleDateString("es-Ar");
};

const formatFecha2 = (data) => {
  let date;
  if (data) {
    date = new Date(data);
  }
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  const hora = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  return dd + "/" + mm + "/" + yyyy +" "+ hora;
};

const obtenerFechaFormateadaActual = () => {
  const hoy = new Date();

  const anio = hoy.getFullYear();
  const mes = String(hoy.getMonth() + 1).padStart(2, '0');
  const dia = String(hoy.getDate()).padStart(2, '0');

  const fechaFormateada = `${anio}-${mes}-${dia}`;

  return fechaFormateada;
};

const inputFecha = (data) => {
  let date;
  if (data) date = new Date(data);
  else {
    date = new Date();
  }

  date.setHours(0, 0, 0, 0);

  return (
    date.getFullYear().toString() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, 0) +
    "-" +
    date.getDate().toString().padStart(2, 0)
  );
};

const formatNumber = (number) => {
  return parseFloat(number).toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const formatString = (value) => {
  let resp = "";
  if (typeof value !== "string") {
    resp = value;
  } else {
    if (value === undefined || value === "") resp = "";
    else resp = value.replace(/\s+/g, " ").trim();
  }

  return resp;
};

const toTitleCase = (string) => {
  if (typeof string !== "string") return string;

  let sentence = formatString(string);
  sentence = sentence.toLowerCase().split(" ");

  var temp = [];
  for (var i = 0; i < sentence.length; i++) {
    if (sentence[i] !== "") {
      if (/^(.)\1+$/.test(sentence[i])) temp.push(sentence[i].toUpperCase());
      else temp.push(sentence[i][0].toUpperCase() + sentence[i].slice(1));
    }
  }
  return temp.join(" ");
};

const getFormData = (data, form = null) => {
  if (!form) {
    form = new FormData();
  }

  for (const [key, value] of Object.entries(data)) {
    if (typeof value !== "string") {
      form.append(key, value);
    } else {
      form.append(key, formatString(value));
    }
  }

  return form;
};

const addToFormData = (data, keys, form) => {
  let tmp = {};
  for (var index in keys) {
    tmp[index] = data[index];
  }

  return getFormData(tmp, form);
};

const calcularPagado = (pagos) => {
  return pagos.reduce((total, obj) => {
    return total + obj.valor / obj.cotiz_usd;
  }, 0);
};

const acreditaciones_cf = (acreditacion = null) => {
  let array = [];

  for (const e of getCommonsAll("acreditaciones")) {
    let tmp = {};
    let uas = getCommons(e.unidad_academica, "unidades-academicas");
    let plan = getCommons(e.plan, "planes");

    if (uas)
      tmp = {
        ...e,
        ua_id: uas.id,
        ua_nombre: uas.nombre,
        plan_codigo: plan.codigo,
      };
    else
      tmp = {
        ...e,
        ua_id: null,
        ua_nombre: "",
        plan_codigo: plan.codigo,
      };

    if (acreditacion == e.id) {
      array = [tmp];
      break;
    } else {
      array.push(tmp);
    }
  }

  return array;
};

const materia_plan = (key = null) => {
  let materias = getCommonsAll("materias");
  let tmp = {};

  materias.forEach((element) => {
    if (!tmp[element.plan]) tmp[element.plan] = [];

    tmp[element.plan].push(element);
  });

  if (key) return tmp[key];
  else return tmp;
};

const carrera_plan = () => {
  let array = getCommonsAll("carreras");
  let carrera_planes = [];

  for (const e of array) {
    let acreditaciones = getCommonsAll("acreditaciones", (x) => {
      return x.carrera === e.id;
    });

    let pl = [];
    for (const p of acreditaciones) {
      if (!pl.some((el) => el.id === p.plan)) {
        let tmp = getCommonsAll("planes", (x) => {
          return x.id === p.plan;
        });

        pl.push(tmp[0]);
      }
    }

    carrera_planes.push({ ...e, planes: pl });
  }

  return carrera_planes;
};

const getCarreraFromPlan = (plan) => {
  let acreditaciones = getCommonsAll("acreditaciones", (x) => {
    return x.plan === plan;
  });
  if (acreditaciones.length > 0) {
    return getCommons(acreditaciones[0]["carrera"], "carreras");
  } else return null;
};

const getPlanVigenteFromCarrera = (carrera) => {
  let acreditaciones = getCommonsAll("acreditaciones", (x) => {
    return x.carrera === carrera;
  });

  acreditaciones = acreditaciones.filter((element) => {
    return element.vigente;
  });
  if (acreditaciones.length > 0) {
    return getCommons(acreditaciones[0]["plan"], "planes");
  } else return null;
};

function getDaysInMonth(year, month) {
  var d = new Date(year, month, 1);
  var days = [];
  while (d.getMonth() === month) {
    days.push(new Date(d));
    d.setDate(d.getDate() + 1);
  }
  return days;
}

function generateYears(startyear, endyear, reverse) {
  const years = [];
  for (let index = startyear; index <= endyear; index++) {
    years.push({ id: index, nombre: index });
  }
  if (reverse) return years.reverse();
  else return years;
}

function groupBy(xs, key) {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

function isDataModified(old_data, new_data) {
  if (!old_data) return true;

  let modified = Object.entries(new_data).map((element) => {
    let key = element[0];
    let value = element[1];
    let old = old_data[key];

    if (old === null) {
      old = "";
    }

    if (value === null) {
      value = "";
    }

    // if (old === "false") {
    //   old = false;
    // }

    // if (old === "true") {
    //   old = true;
    // }

    return String(old) != String(value);
  });

  // console.log(old_data, new_data);
  return modified.some((e) => e === true);
  // if (modified.every((e) => e === false)) {
  //   props.cancelar();
  //   return;
  // }
}

const getLastParentOrganizacion = (org_id) => {
  let obj = getCommons(org_id, "organizaciones");
  if (obj.parent) return getLastParentOrganizacion(obj.parent);
  else return obj;
};

const getFirstParentOrganizacion = (org_id) => {
  let obj = getCommons(org_id, "organizaciones");
  if (obj.parent) return getCommons(obj.parent, "organizaciones");
  else return obj;
};

const getChildrenOrganizacion = (org_id) => {
  let organizaciones = getCommonsAll(
    "organizaciones",
    (x) => x.parent == org_id
  );

  return organizaciones;
};

const list_organizaciones = () => {
  return getCommonsAll("organizaciones", (x) => {
    return getChildrenOrganizacion(x.id).length > 0;
  });
};

const list_suborganizaciones = (org_id = null) => {
  let tmp = [];
  if (org_id) {
    tmp = getChildrenOrganizacion(org_id);
  } else {
    tmp = getCommonsAll("organizaciones", (x) => x.parent != null);
  }

  tmp = tmp.filter((o) => {
    return !list_organizaciones()
      .map((x) => x.id)
      .includes(o.id);
  });

  return tmp;
};

const toObject = (array, key, value = null) => {
  let tmp = {};
  for (const i of array) {
    if (!tmp[i[key]]) tmp[i[key]] = [];
    if (!value) tmp[i[key]].push(i[value]);
    else tmp[i[key]].push(i);
  }
  return tmp;
};

const trimString = (text, cant) => {
  if (text.length > cant) return text.substring(0, cant) + "...";
  else return text;
};

const get_estado_fecha = (estados, fecha) => {
  let tmp = estados.filter((e) => {
    let fecha_estado = new Date(e.fecha);
    let fecha_busqueda = new Date(fecha);
    return fecha_estado <= fecha_busqueda;
  });

  if (tmp.length > 0) return tmp[0]["valor"];
  else if (estados.length > 0) return estados[estados.length - 1]["valor"];
  else return "SIN ESTADO";
};

const getValueFromPath = (object, path) => {
  if (object && path)
    return path.split(".").reduce((res, prop) => res[prop], object);
  return null;
};

const isFacturaAlumno = (element) => {
  return element.doctype === "Sales Invoice";
};

const getSignedAmount = (element) => {
  if (isFacturaAlumno(element)) {
    return element.total;
  } else {
    return element.total * -1;
  }
};

const formatSaldo = (saldo) => {
  if (saldo < 0) {
    return formatNumber(saldo);
  } else if (saldo > 0) {
    return `+${formatNumber(saldo)}`;
  } else {
    return formatNumber(saldo);
  }
};

const checkSizeFile = (file)=>{
  const error = "ERROR ese formato no lo aceptamos"
  const fileFormat = file.name.split(".")[file.name.split(".").length-1]
  const fileSize = file.size
  if(fileFormat == "pdf" || fileFormat == "docx" || fileFormat == "odf" || fileFormat == "doc"){
    return !(fileSize <=5000000)
  }else if (fileFormat == "png" || fileFormat == "jpg"|| fileFormat == "jpeg"){
    return !(fileSize <= 1000000)
  }else{
    return error
  }
}

const validacionCuit = (cuit) => {
  if (!/^\d+$/.test(cuit)) {
    return 'Debe ingresar sólo números';
  }
  if (!/^\d{11}$/.test(cuit)) {
    return 'Debe ingresar 11 números';
  }
  return true;
};

export {
  getCommons,
  getCommonsAll,
  getOptions,
  getChoices,
  getChoiceValue,
  formatEstadoAlumnoClass,
  formatEstadoDocenteClass,
  formatLugarNacimientoAlumno,
  formatFechaNacimiento,
  get_estado_fecha,
  notas,
  tipos_responsables,
  isDataModified,
  // documentacion,
  // getConvTituloExt,
  getFormaIngreso,
  // getHorasTrabajo,
  // getInstruccion,
  // getNivelTitulo,
  // getOrigenTitulo,
  // getProcedencia,
  getObligatoriedad,
  getDuracion,
  getTipoMateria,
  // getJerarquiaCargo,
  // getParciales,
  getFileIcon,
  formatFecha,
  formatFecha2,
  formatString,
  toTitleCase,
  inputFecha,
  // tipo_carrera,
  formatNumber,
  calcularPagado,
  meses_txt,
  normalize_str,
  acreditaciones_cf,
  default_txt,
  carrera_plan,
  getDaysInMonth,
  generateYears,
  getFormData,
  addToFormData,
  getVersion,
  groupBy,
  materia_plan,
  getFirstParentOrganizacion,
  getLastParentOrganizacion,
  getChildrenOrganizacion,
  list_organizaciones,
  list_suborganizaciones,
  getCarreraFromPlan,
  getPlanVigenteFromCarrera,
  toObject,
  trimString,
  getValueFromPath,
  isFacturaAlumno,
  getSignedAmount,
  formatSaldo,
  // divisiones,
  checkSizeFile,
  validacionCuit,
  obtenerFechaFormateadaActual
};
