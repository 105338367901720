import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { AuthComponent } from "./components/Authentication/AuthComponent.jsx";
import "./assets/lib/fontawesome/css/all.min.css";
import "./assets/lib/ionicons/css/ionicons.min.css";
// import "./assets/lib/typicons.font/typicons.css";
// import "./assets/lib/flag-icon-css/css/flag-icon.min.css";

import "./assets/css/dashforge.css";
import "./assets/css/dashforge.auth.css";
import "./assets/css/dashforge.dashboard.css";
import "../node_modules/react-quill/dist/quill.snow.css"

// import "./assets/css/dashforge.profile.css";

// import "./assets/lib/chart.js/Chart.bundle.min.js";

// require("feather-icons");
// require("bootstrap");
// require("jszip");
// global.JSZip = require("jszip");

// var pdfMake = require("pdfmake/build/pdfmake.js");
// var pdfFonts = require("pdfmake/build/vfs_fonts.js");
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

// require("datatables.net-dt");
// require("datatables.net-buttons-dt");
// require("datatables.net-buttons/js/buttons.html5.js");
// require("datatables.net-buttons/js/buttons.print.js");
// require("datatables.net-responsive-dt");

ReactDOM.render(
  <AuthComponent>
    <App />
  </AuthComponent>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
