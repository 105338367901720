import React, { useState, useEffect, useContext } from "react";
import { config } from "../../config";
import { useCommons, useConnection, useOptions } from "./MyHooks";
// import CardComponent from "../../views/Templates/CardComponent";
import ErrorElement from "../../views/Templates/ErrorElement";
import shortid from "shortid";
import { AuthContext } from "../../components/Authentication/AuthComponent";

export default function useLoadingCommons() {
  const [progress, setProgress] = useState(0);
  const [errores, setErrores] = useState([]);
  // const [loading, setLoading] = useState(true);

  // function doLoading() {
  const auth = useContext(AuthContext);

  const localidad = useCommons("localidad", config.api_localidad);
  const pais = useCommons("pais", config.api_pais);
  const documento = useCommons("documento", config.api_documento);
  const provincia = useCommons("provincia", config.api_provincia);
  const identidad_genero = useCommons("identidad_genero", config.api_identidad_genero);
  const pueblo_originario = useCommons("pueblo_originario", config.api_pueblos_originarios);
  // const comprobantes = useCommons("comprobantes", config.api_comprobantes);
  const carreras = useCommons("carreras", config.api_carreras);
  const organizaciones = useCommons(
    "organizaciones",
    config.api_organizaciones
  );
  const materias = useCommons("materias", config.api_materias);
  const extensiones_aulicas = useCommons(
    "extensiones_aulicas",
    config.api_extensiones_aulicas
  );
  const acreditaciones = useCommons(
    "acreditaciones",
    config.api_acreditaciones
  );
  const planes = useCommons("planes", config.api_planes);
  const documentacion_persona = useCommons("documentacion_persona",config.api_tipo_documento_persona);
  const documentacion = useCommons("documentacion", config.api_documentacion);
  const ua = useCommons("unidades-academicas", config.api_unidades_academicas);
  const cargos = useCommons("cargos", config.api_cargos);
  const dedicacion = useCommons("dedicacion", config.api_dedicacion);

  /*const listas_precios = useCommons(
    "listas_precios",
    config.api_listas_precios
  );*/
  const persona_options = useOptions("persona_options", config.api_personas);
  const cargo_options = useOptions("cargo_options", config.api_cargos_docencia);
  const alumno_options = useOptions(
    "alumno_options",
    config.api_alumnos_basico
  );
  const inscripcion_options = useOptions(
    "inscripcion_options",
    config.api_inscripcion
  );
  const estado_options = useOptions("estado_carrera", config.api_estados);
  const cursada_options = useOptions(
    "cursada_options",
    config.api_materia_alumnos_inscriptos
  );
  const materia_options = useOptions("materia_options", config.api_materias);

  const parcial_options = useOptions(
    "parcial_options",
    config.api_examen_parcial
  );
  const final_options = useOptions("final_options", config.api_mesa_examen);
  const ticket_options = useOptions("ticket_options", config.api_tickets);
  const variables = useConnection(true);
  const auth_data = useConnection(true);
  const list = [
    {
      url: config.api_user_permissions,
      method: "GET",
      body: null,
      error_msg: "Error al obtener permisos de usuario",
    },
    // {
    //   url: config.api_groups,
    //   method: "GET",
    //   body: null,
    //   error_msg: "Error al obtener grupo de usuario",
    // },
  ];
  useEffect(() => {
    auth_data.list(list);
    variables.get(config.api_variables);
  }, []);

  document.body.style = "background: #FFFF";

  const load_array = [
    localidad.isLoading,
    pais.isLoading,
    documento.isLoading,
    provincia.isLoading,
    // comprobantes.isLoading,
    carreras.isLoading,
    organizaciones.isLoading,
    // suborganizaciones.isLoading,
    extensiones_aulicas.isLoading,
    acreditaciones.isLoading,
    documentacion.isLoading,
    ua.isLoading,
    cargos.isLoading,
    dedicacion.isLoading,
    //listas_precios.isLoading,
    planes.isLoading,
    // tipo_carreras.isLoading,
    alumno_options.isLoading,
    estado_options.isLoading,
    persona_options.isLoading,
    cursada_options.isLoading,
    materias.isLoading,
    materia_options.isLoading,
    parcial_options.isLoading,
    final_options.isLoading,
    inscripcion_options.isLoading,
    cargo_options.isLoading,
    auth_data.isLoading,
    variables.isLoading,
    ticket_options.isLoading,
    // resoluciones.isLoading,
    documentacion_persona.isLoading,
    identidad_genero.isLoading,
    pueblo_originario.isLoading,
  ];

  const error_array = [
    localidad.error,
    pais.error,
    documento.error,
    provincia.error,
    // comprobantes.error,
    carreras.error,
    organizaciones.error,
    // suborganizaciones.error,
    extensiones_aulicas.error,
    acreditaciones.error,
    documentacion.error,
    ua.error,
    cargos.error,
    dedicacion.error,
    //listas_precios.error,
    planes.error,
    // tipo_carreras.error,
    alumno_options.error,
    estado_options.error,
    persona_options.error,
    cursada_options.error,
    materias.error,
    materia_options.error,
    parcial_options.error,
    final_options.error,
    inscripcion_options.error,
    cargo_options.error,
    auth_data.error,
    variables.error,
    ticket_options.error,
    // resoluciones.error,
    documentacion_persona.error,
    identidad_genero.error,
    pueblo_originario.error,
  ];

  useEffect(() => {
    let temp = (load_array.filter((v) => !v).length * 50) / load_array.length;
    let body = [];

    setProgress(Math.round(temp / 10) * 10);

    // if (temp > 40)
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 1000);

    error_array.forEach((e) => {
      if (e) {
        body.push(<ErrorElement key={shortid.generate()} text={e} />);
      }
    });

    if (auth_data.status === "done") {
      if (auth_data.data) {
        if (auth_data.data[0].data) {
          if (auth.serialize_permissions(auth_data.data[0].data).includes('alumno')) {
            body.push(
              <ErrorElement key={shortid.generate()} text={list[0].error_msg} />
            );
          } else {
            auth.setSessionData(auth_data.data[0].data);
          }
        } else
          body.push(
            <ErrorElement key={shortid.generate()} text={list[0].error_msg} />
          );

        // if (auth_data.data[1].data) {
        //   auth.setGroupData(auth_data.data[1].data);
        // } else
        //   body.push(
        //     <ErrorElement key={shortid.generate()} text={list[1].error_msg} />
        //   );
      }
    }

    if (variables.status === "done") {
      if (variables.data) {
        auth.setVariables(variables.data);
      }
    }

    setErrores(body);
  }, load_array);

  // if (loading) {
  //   return (
  //     <div className="content pd-0">
  //       <div className="container">
  //         <CardComponent style="mg-t-200 shadow">
  //           <div className="progress ht-20">
  //             <div
  //               style={{ width: progress + "%" }}
  //               className={"progress-bar"}
  //               role="progressbar"
  //             >
  //               Cargando {progress}%
  //             </div>
  //           </div>
  //         </CardComponent>
  //       </div>
  //     </div>
  //   );
  // } else {
  //   if (errores.length !== 0) {
  //     return errores;
  //   } else {
  //     return <>{props.children}</>;
  //   }
  // }
  // }
  return { errores, progress };
}
