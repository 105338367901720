import React, { useState, useEffect } from "react";
// import { config } from "../../config.js";
// import ContentView from "../../views/Templates/ContentView.jsx";
const AuthContext = React.createContext();

const serialize_permissions = (permisos) => {
  let p = new Set();
  permisos.user_permissions.forEach((element) => {
    p.add(element.codename);
  });

  permisos.groups.forEach((element) => {
    p.add(element.name);
    element.permissions.forEach((e) => {
      p.add(e.codename);
    });
  });

  return Array.from(p);
};

function AuthComponent(props) {
  // const [loading, setLoading] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(checkLoginStatus());
  // const [token, setToken] = useState(getToken());
  const [session_data, setData] = useState(null);
  const [group_data, setGroupData] = useState(null);
  const [variables, setVariables] = useState([]);

  function checkLoginStatus() {
    return sessionStorage.getItem("isLoggedIn");
  }

  function getToken() {
    return sessionStorage.getItem("token");
  }

  function getGroupData() {
    return group_data;
  }

  function getGroupDataName(id) {
    let tmp = [];
    if (group_data) {
      tmp = group_data.filter((x) => {
        return x.id === id;
      });
    }

    if (tmp.length > 0) return tmp[0].name;
    else return "";
  }

  function getSessionData() {
    return session_data;
  }

  function setSessionData(x) {
    sessionStorage.setItem(
      "server-date",
      new Date(x.last_login).setHours(0, 0, 0, 0)
    );

    let tmp = {
      ...x,
      user: x.id,
      list_permissions: serialize_permissions(x),
      admin: x.is_staff,
    };

    // console.log(tmp);

    setData(tmp);
  }

  function setToken(data) {
    sessionStorage.setItem("token", data.key);
  }

  function doLogin() {
    console.log("Log in....");
    sessionStorage.setItem("isLoggedIn", true);
    setLoggedIn(true);
  }

  function doLogout() {
    console.log("Log out....");
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("server-date");
    setLoggedIn(false);
  }

  function getVariable(key) {
    let variable = variables.find((x) => x.nombre === key);
    if (variable) {
      return variable.valor;
    }
    return undefined;
  }

  return (
    <AuthContext.Provider
      value={{
        doLogin,
        doLogout,
        getToken,
        setToken,
        isLoggedIn,
        checkLoginStatus,
        getSessionData,
        setSessionData,
        setGroupData,
        getGroupData,
        getGroupDataName,
        getVariable,
        setVariables,
        serialize_permissions,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthComponent };
