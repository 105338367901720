let server =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.port ? ":" + window.location.port : "");
// let server = "http://localhost:3000";
// let server = "http://192.168.0.11";
let urlv1 = server + "/api/v1";
let urlv2 = server + "/api/v2";
let urlv3 = server + "/api/v3";
let auth = server + "/dj-rest-auth";

let config = {
  server: server,
  apiv1: urlv1,
  apiv2: urlv2,
  apiv3: urlv3,
  api_planes: urlv1 + "/planes/",
  api_carreras: urlv1 + "/carreras/",
  api_acreditaciones: urlv1 + "/acreditaciones/",
  api_alumnos_basico: urlv1 + "/alumnos/",
  api_alumnos_busqueda: urlv2 + "/list_alumnos_inscripciones",
  api_alumnos_busqueda_extendida: urlv2 + "/list_inscripciones/",
  api_alumnos_busqueda_rematriculaciones: urlv2 + "/list_rematriculaciones",
  api_docentes: urlv1 + "/docentes/",
  api_directivos: urlv1 + "/directivos/",
  api_personas: urlv1 + "/personas/",
  api_inscripcion: urlv1 + "/alumno_inscripcion/",
  api_estados: urlv1 + "/alumno_inscripcion_estados/",
  api_estados_manuales: urlv1 + "/alumno_inscripcion_estados_manuales/",
  api_login: auth + "/login/",
  api_logout: auth + "/logout/",
  api_user_permissions: auth + "/user/",
  api_users: urlv1 + "/users/",
  api_groups: urlv1 + "/groups/",
  api_change_pass: auth + "/password/change/",
  api_persona_documentacion_file: urlv1 + "/persona_documentos_files/",
  api_tipo_documento_persona: urlv1 + "/tipo_documeto_persona/",
  api_documentacion: urlv1 + "/documentos/",
  api_localidad: urlv1 + "/localidad/",
  api_provincia: urlv1 + "/provincia/",
  api_pais: urlv1 + "/paises/",
  api_documento: urlv1 + "/tipo_documento",
  api_comprobantes: urlv1 + "/comprobantes",
  api_unidades_academicas: urlv1 + "/uas",
  api_cursada_materias: urlv2 + "/alumno_cursada_materias/",
  api_cursada_materia_detail: urlv2 + "/alumno_cursada_materia_detail/",
  // api_cursada_alumnos: urlv2 + "/alumnos_cursada_materia/",
  api_alumnos_inscriptos_examen_final:
    urlv1 + "/alumnos_inscripciones_examenes/",
  api_alumnos_inscriptos_examen_final_autogestion: urlv2 + "/inscripcion_finales_autogestion/",
  api_alumnos_notas_examen_parcial: urlv2 + "/notas_parcial/",
  api_alumnos_examen_parcial: urlv1 + "/alumnos_examenes_parciales/",
  api_materias: urlv1 + "/materias/",
  api_materia_detail: urlv2 + "/materia_detail/",
  // api_materias_busqueda: urlv2 + "/list_carrera_plan",
  api_materias_instancias: urlv1 + "/materias_instancias/",
  api_materia_instancia_detail: urlv2 + "/materia_instancia_detail/",
  api_materia_alumnos_inscriptos: urlv1 + "/alumno_cursado/",
  api_materia_instancia_alumnos_divisiones:
    urlv2 + "/list_alumnos_materia_instancia/",
  api_titulos: urlv1 + "/personas_titulos/",
  api_estadistica_carreras_alumnos: urlv2 + "/estadistica_carreras_alumnos/",
  api_estadistica_cargos_carreras: urlv2 + "/estadistica_cargos_carreras/",
  api_resoluciones: urlv1 + "/resoluciones/",
  api_preinscriptos: urlv1 + "/pre_inscriptos/",
  api_preinscriptos_list: urlv2 + "/get_pre_inscriptos/",
  api_documentacion_check: urlv1 + "/alumno_documentos_checks/",
  api_documentacion_file: urlv1 + "/alumno_documentos_files/",
  api_alumno_acreditacion: urlv1 + "/alumnos_acreditaciones/",
  api_alumno_asistencia: urlv1 + "/alumnos_asistencias/",
  api_examen_parcial: urlv1 + "/parciales/",
  api_mesa_examen: urlv1 + "/finales/",
  api_cargos_docencia: urlv1 + "/cargos_docencia/",
  // api_cargos_carreras: urlv1 + "/cargos_carreras/",
  api_cargos_gestion: urlv1 + "/cargos_gestion/",
  api_list_cargos: urlv2 + "/list_cargos/",
  api_cargos: urlv1 + "/cargo/",
  api_dedicacion: urlv1 + "/dedicacion/",
  api_materia_dias_habiles: urlv1 + "/registros_asistencia/",
  api_materia_cronograma: urlv1 + "/cronogramas/",
  api_create_cronograma: urlv2 + "/create_cronograma/",
  api_extensiones_aulicas: urlv1 + "/extensiones_aulicas",
  api_organizaciones: urlv1 + "/organizaciones/",
  api_suborganizaciones: urlv1 + "/suborganizaciones/",
  api_sidcer: urlv2 + "/process_sidcer/",
  api_list_media: urlv2 + "/list_commons_media_files/",
  api_media: server + "/media",
  api_list_notas: urlv1 + "/alumnos_inscripciones_examenes/",
  api_correlatividades: urlv1 + "/materia_correlatividad/",
  api_equivalencias: urlv1 + "/alumnos_equivalencias/",
  api_inscripcion_masiva: urlv2 + "/inscripcion_cursadas/",
  api_list_inscriptos_mesa_web: urlv2 + "/inscripciones_finales_portal/",
  api_list_posibles_inscriptos_mesa: urlv2 + "/posibles_inscriptos_final_list/",
  api_list_posibles_inscriptos_mesa_no_cursada:
    urlv2 + "/posibles_inscriptos_final_sin_cursada_list/",
  api_tickets: urlv1 + "/tickets/",
  api_inscripcion_historica: urlv1 + "/inscripciones_historicas/",
  api_process_nominal: urlv2 + "/process_info_nominal/",
  api_batch_asistencias: urlv2 + "/subir_asistencias/",
  api_batch_condiciones: urlv2 + "/update_condicion_final/",
  api_batch_divisiones: urlv2 + "/update_divisiones/",
  api_batch_inscripciones: urlv2 + "/inscripcion_cursado/",
  api_batch_aprobaciones: urlv2 + "/inscripcion_singular/",
  api_parciales_totales_materia: urlv2 + "/notas_parciales_totales/",
  api_abm_materia: urlv2 + "/procesos_materia/",
  api_reportes: urlv2 + "/reportes/",
  api_alumnos_cc_data: urlv1 + "/alumnos_cc/",
  api_alumnos_cc_movimientos: urlv2 + "/movimientos_alumnocc/",
  api_computo_materias_adm: urlv2 + "/computar_materias_adm/",
  // api_listas_precios: urlv1 + "/lista_precios/",
  api_facturas_ventas: urlv2 + "/facturas_ventas/",
  api_recibos_pagos: urlv2 + "/recibos_pagos/",
  api_variables: urlv1 + "/variables/",
  api_csv_estado: urlv2 + "/alumno_cambio_estado/",
  api_generar_usuario_alumno: urlv3 + "/generar_usuario_alumno/",
  api_reset_password_send: urlv3 + "/reset-send-siigaa/",
  api_estados_sacpro: urlv3 + "/estados_sacpro/",
  api_eventos_calendario: urlv1 + "/eventos_calendario/",
  api_notas_version: urlv1 + "/notas_version/",
  api_ultimas_novedades: urlv1 + "/ultimas_novedades/",
  api_new_creacion_finales: urlv3 + "/crear_examen_final/",
  api_alumnos_historial_inscriptos: urlv3 + "/historial-examen-autogestion/",
  api_inscripciones_a_materias: urlv1 + "/inscripciones_materias/",
  api_inscripcion_a_materia_unica: urlv3 + "/crear-periodo-insc-materia-unica/",
  api_inscripciones_a_carreras: urlv1 + "/inscripciones_carreras/",
  api_rematriculaciones_anuales: urlv1 + "/rematriculaciones_anuales/",
  api_identidad_genero: urlv1 + "/identidad_genero/",
  api_pueblos_originarios: urlv1 + "/pueblo_originario/",
  api_inscripcion_a_materia_carrera_entera: urlv3 + "/crear-periodo-insc-materia-carrera-entera/",
};

export { config };
