import React, { useState, useEffect, useContext } from "react";
import { config } from "../../config";
import { AuthContext } from "../Authentication/AuthComponent";

const useConnection = (initLoading = false) => {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(initLoading);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("");
  const [method, setMethod] = useState("");
  // const [content, setContent] = useState(null);
  const auth = useContext(AuthContext);
  const token = auth ? auth.getToken() : null;

  const fetchData = (url, method, body) => {
    // const isLoggedIn = auth ? auth.checkLoginStatus() : false;
    // const token = auth ? auth.getToken() : null;

    let headers = { Accept: "application/json" };

    if (token) headers["Authorization"] = "Token " + token;

    // if (content) headers["Content-Type"] = content;

    setMethod(method);

    setLoading(true);
    setStatus("loading");

    setError(null);
    setData(null);

    let url_txt = url.split(config.server)[1];
    let options = {
      method: method,
      headers: headers,
    };

    if (body) {
      options["body"] = body;
      if (!(body instanceof FormData)) {
        options.headers["Content-Type"] = "application/json";
      }
    }

    fetch(url, options)
      .then((response) => {
        return response.text().then((x) => {
          try {
            let data = JSON.parse(x);
            return { status: response.status, data: data };
            // Do your JSON handling here
          } catch (err) {
            return { status: response.status, data: x };
            // It is text, do you text handling here
          }
        });
      })
      .then((r) => {
        if (r.status < 300) {
          setData(r.data);
        } else {
          let msg;
          if (r.status === 400) {
            msg = "Solicitud Incorrecta: " + url_txt;
          } else if (r.status === 401) {
            msg = "No autorizado: " + url_txt;
          } else if (r.status === 404) {
            msg = "No encontrado: " + url_txt;
          } else if (r.status === 403) {
            msg = "No tienes permisos para realizar esta acción. " + url_txt;
          } else {
            msg = "Error Desconocido: " + url_txt;
          }
          console.log(r.data);
          setError({ code: r.status, msg: msg, obj: r.data });
        }
      })
      .catch((er) => {
        console.log(er);
        setError({
          code: 0,
          msg: "Error de Conexión: " + url_txt,
          obj: er,
        });
      })
      .finally(() => {
        setLoading(false);
        setStatus("done");
      });
  };

  const get = (url) => {
    fetchData(url, "GET", null);
  };

  const options = (url) => {
    fetchData(url, "OPTIONS", null);
  };

  const post = (url, body) => {
    fetchData(url, "POST", body);
  };

  const put = (url, body) => {
    fetchData(url, "PUT", body);
  };

  const patch = (url, body) => {
    fetchData(url, "PATCH", body);
  };

  const del = (url) => {
    fetchData(url, "DELETE", null);
  };

  // const list_ = (list) => {
  //   Promise.all(list.map((e) => fetchData(e.url, e.method, e.body)));
  // };

  const list = (list) => {
    // const isLoggedIn = auth ? auth.checkLoginStatus() : false;
    // const token = auth ? auth.getToken() : null;
    let headers = { Accept: "application/json" };

    if (token) headers["Authorization"] = "Token " + token;

    // if (content) headers["Content-Type"] = content;

    setStatus("loading");
    setLoading(true);
    Promise.all(
      list.map((e) => {
        let options = {
          method: e.method,
          headers: headers,
        };

        if (e.body && e.method !== "GET") {
          options.body = e.body;
          if (!(e.body instanceof FormData)) {
            options.headers["Content-Type"] = "application/json";
          }
        }
        return fetch(e.url, options);
      })
    )
      .then((response) =>
        Promise.all(
          response.map((r) => {
            return r.text().then((x) => {
              try {
                let d = JSON.parse(x);
                return { status: r.status, data: d };
              } catch (e) {
                return { status: r.status, data: x };
              }
            });
          })
        ).then((r) => {
          let result = [];
          r.forEach((element) => {
            let d = null;
            let e = null;
            if (element.status < 300) {
              d = element.data;
            } else {
              console.log(element);
              e = { code: element.status, msg: "Error", obj: element.data };
            }
            result.push({ data: d, error: e });
          });
          setData(result);
        })
      )
      .catch((er) => {
        console.log(er);
        setError({ code: 0, msg: "Error de Conexion", obj: er.message });
      })
      .finally(() => {
        setStatus("done");
        setLoading(false);
      });
  };

  return {
    data,
    isLoading,
    error,
    status,
    method,
    // setContent,
    get,
    options,
    post,
    patch,
    put,
    del,
    list,
  };
};

const useCommons = (commons, url_commons) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const commons_ = useConnection();

  useEffect(() => {
    // if (!sessionStorage.getItem(commons)) {
    commons_.get(url_commons);
    // } else {
    //   setLoading(false);
    // }
  }, []);

  useEffect(() => {
    if (commons_.status === "done") {
      if (commons_.data) {
        sessionStorage.setItem(commons, JSON.stringify(commons_.data));
        setLoading(false);
      }

      if (commons_.error) {
        setError("Error obteniendo datos de: " + commons);
        setLoading(false);
      }
    }
  }, [commons_.status]);

  return { error, isLoading };
};

const useOptions = (op, url_options) => {
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const conn = useConnection();

  useEffect(() => {
    if (!sessionStorage.getItem(op)) {
      conn.options(url_options);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (conn.status === "done") {
      if (conn.data) {
        let tmp = conn.data;
        sessionStorage.setItem(op, JSON.stringify(tmp));
      } else {
        // console.log(url_options);
        // console.log(conn.data);
        setError("Error obteniendo " + op);
      }

      if (conn.error) {
        setError("Error obteniendo " + op);
      }
      setLoading(false);
    }
  }, [conn.status]);

  return { error, isLoading };
};

const useNotifications = (
  conn,
  loading,
  error,
  success,
  finish,
  errors_only = false
) => {
  useEffect(() => {
    if (conn.error) {
      error(conn.error.msg);
      finish && finish();
      loading && loading(false);
    }
  }, [conn.error]);

  useEffect(() => {
    if (conn.status === "loading") {
      loading && loading(true);
    }

    if (conn.status === "done") {
      loading && loading(false);
      if (conn.data) {
        if (Array.isArray(conn.data)) {
          if (conn.data.length > 0) {
            if (
              conn.data.every((e) => {
                return e.error && e.error !== null;
              })
            )
              error("Error: Los datos no fueron guardados");
            else if (
              conn.data.some((e) => {
                return e.error && e.error !== null;
              })
            )
              error("Error: Algunos datos no fueron guardados");
            else
              !errors_only && success("Los datos fueron guardados con éxito");
          }
        } else {
          if (Object.keys(conn.data).length > 0) {
            !errors_only && success("Los datos fueron guardados con éxito");
          }
        }
      }
      finish && finish();
    }
  }, [conn.status]);
};

const useRequest = (success, error) => {
  const conn = useConnection();

  useEffect(() => {
    if (conn.status === "done") {
      if (conn.data) {
        return success(conn.data);
      } else if (conn.error) error(conn.error.msg);
    }
  }, [conn.status]);

  const get = (url) => {
    conn.get(url);
  };

  const list = (l) => {
    conn.list(l);
  };

  return { get, list, conn };
};

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export {
  useCommons,
  useConnection,
  useNotifications,
  useOptions,
  useRequest,
  useWindowSize,
};
