import React, { Suspense, useContext, useEffect } from "react";
import { AuthContext } from "./components/Authentication/AuthComponent.jsx";
import Spinner from "./views/Templates/Spinner.jsx";
// import { useBeforeunload } from "react-beforeunload";
import AuthenticatedApp from "./components/Authentication/AuthenticatedApp";
import UnAuthenticatedApp from "./components/Authentication/UnAuthenticatedApp";
// import {useConnection} from "./components/Utils/MyHooks";
// import {config} from "./config";

// const AuthenticatedApp = React.lazy(() =>
//   import("./components/Authentication/AuthenticatedApp.jsx")
// );
// const UnAuthenticatedApp = React.lazy(() =>
//   import("./components/Authentication/UnAuthenticatedApp.jsx")
// );

function App() {
  const auth = useContext(AuthContext);
  // const conn = useConnection();

  // useBeforeunload(() => conn.post(config.api_logout));

  // useEffect(() => {
  //   if (conn.status === "done") {
  //     if (!conn.error) {
  //       auth.doLogout();
  //     }
  //   }
  // }, [conn.status]);

  return (
    <>
      {/* <Suspense fallback={<Spinner />}> */}
      {!auth.isLoggedIn && <UnAuthenticatedApp />}
      {auth.isLoggedIn && <AuthenticatedApp />}
      {/* </Suspense> */}
    </>
  );
}

export default App;
