import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthComponent";
import Swal from "sweetalert2";

const permiso_err = () => {
  Swal.fire({
    title: "No tiene permisos para realizar esta acción",
    icon: "error",
    confirmButtonText: "Aceptar",
    showCancelButton: false,
  });
};

const checkPermisos = (permisos, list) => {
  if (!list) return true;
  if (!permisos) return false;
  if (permisos.admin) return true;
  else if (list && list.length === 0) return true;
  else {
    return permisos.list_permissions.some((r) => list.includes(r));
  }
};

const useWrapperPermisos = (permisos) => {
  const context = useContext(AuthContext);
  const authorized = checkPermisos(context.getSessionData(), permisos);

  const checkAction = (action) => {
    if (authorized) return action();
    else return permiso_err();
  };

  return { authorized, checkAction };
};

const WrapperPermisos = (props) => {
  const context = useContext(AuthContext);
  if (checkPermisos(context.getSessionData(), props.permisos))
    return props.children;
  else return null;
};

const PermisosAcademico = (props) => (
  <WrapperPermisos permisos={["academico"]}>{props.children}</WrapperPermisos>
);

const PermisosAdministrativo = (props) => (
  <WrapperPermisos permisos={["administrativo"]}>{props.children}</WrapperPermisos>
);

const PermisosAdmin = (props) => (
  <WrapperPermisos permisos={["admin"]}>{props.children}</WrapperPermisos>
);

const PermisosDefault = (props) => (
  <WrapperPermisos permisos={["personal_apoyo"]}>{props.children}</WrapperPermisos>
);

const PermisosBloqueos = (props) => (
  <WrapperPermisos permisos={["bloqueos_manuales"]}>{props.children}</WrapperPermisos>
);

const PermisosInscAdmin = (props) => (
  <WrapperPermisos permisos={["pagos_ingresos"]}>{props.children}</WrapperPermisos>
);

const PermisosInscAcad = (props) => (
  <WrapperPermisos permisos={["insc_academico"]}>{props.children}</WrapperPermisos>
);

const usePermisosDefault = (list) => {
  let permisos = ["personal_apoyo"];
  if (list && list.length !== 0) permisos = list;
  // console.log(permisos);
  return useWrapperPermisos(permisos);
};

const usePermisosAcademico = (list) => {
  let permisos = ["academico"];
  if (list && list.length !== 0) permisos = list;

  return useWrapperPermisos(permisos);
};

const usePermisosAdministrativo = (list) => {
  let permisos = ["administrativo"];
  if (list && list.length !== 0) permisos = list;

  return useWrapperPermisos(permisos);
};


export {
  useWrapperPermisos,
  WrapperPermisos,
  PermisosAcademico,
  PermisosAdministrativo,
  PermisosAdmin,
  PermisosDefault,
  usePermisosDefault,
  usePermisosAcademico,
  usePermisosAdministrativo,
  PermisosBloqueos,
  PermisosInscAdmin,
  PermisosInscAcad,
};
