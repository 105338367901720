import React from "react";

export default function CardComponent(props) {
  return (
    <React.Fragment>
      <div className={props.style ? "card " + props.style : "card"}>
        {props.header ? (
          <div className="card-header d-flex align-items-center justify-content-between ht-50">
            {/* tx-13 tx-spacing-1 tx-uppercase tx-semibold  */}
            <h6 className="mg-b-0 tx-uppercase tx-13">{props.header}</h6>
            {props.button ? (
              <div className="float-right">{props.button}</div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <div className="card-body">{props.children}</div>
        {props.footer ? (
          <div className="card-footer text-center tx-13">
            <a href="#" onClick={props.footer_link} className="link-03">
              {props.footer}
              <i className="icon ion-md-arrow-down mg-l-5"></i>
            </a>
          </div>
        ) : (
          // <div className="card-footer pd-20">{props.footer}</div>
          ""
        )}
      </div>
    </React.Fragment>
  );
}
