import React, { useEffect, useState, useContext } from "react";
// import { useConnection } from "../components/Utils/MyHooks";
import { MenuContext } from "../components/Utils/MenuContext";
// import { AuthContext } from "../components/Authentication/AuthComponent";

import NavBar from "../components/NavBar/NavBar";
import SideBar from "../components/SideBar/SideBar";
// import { config } from "../config";

export default function MainLayout() {
  const menu = useContext(MenuContext);
  document.body.style = "background: none";

  return (
    <>
      <SideBar />
      <div className="content pd-0">
        <NavBar />
        <div className="content content-fixed pd-b-0">
          {menu.currentView}
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
  // }
  // }
}
